*{
    transition: all 2s;
}

html {
    scroll-behavior: smooth;
  }

body {
  background:radial-gradient(ellipse at center, rgb(251, 255, 234) 0%, rgb(255, 250, 234) 35%, #B7E8EB 100%);
  position: relative;
}

/* ocean control */

.ocean { 
    height: 5%;
    width:100%;
    position:fixed;
    bottom:0;
    left:0;
    background: #015871;
    z-index: -200;
  }
  
  .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
    position: absolute;
    bottom: 0;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }
  
  .wave:nth-of-type(2) {
    bottom: 0;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }
  
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  
  @keyframes swell {
    0%, 100% {
      transform: translate3d(0,-25px,0);
    }
    50% {
      transform: translate3d(0,5px,0);
    }
  }

  .sun{
      height: 150px;
      width: 150px;
      background: radial-gradient(ellipse at center, rgb(233, 183, 48) 0%, rgb(228, 217, 70) 35%, #ebe6b7 100%);
      border-radius: 100%;
      position: fixed;
      z-index: -100;
      right: 100px;
      top: 100px;
      box-shadow: 10px 10px 15px 5px #ebe6b7;
  }

div.hamburger{
      display: none;
  }

nav.navigation{
    background-color: rgba(252, 249, 227, 0.8);
    padding-top: 5px;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-shadow: 10px 10px 60px 5px rgb(28, 87, 97);
}

nav.navigation:hover{
    box-shadow: 10px 10px 60px 5px rgb(255, 244, 182);
}

nav.navigation ul{
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
    margin: 0;
}

nav.navigation ul li{
    list-style-type: none;
    margin-bottom: 20px;
}

nav.navigation ul li:hover{
    transform: scale(1.05);
}

nav.navigation > ul > li > a{
    text-decoration: none;
    color: black;
    font-family: lato;
}

nav.navigation > ul > li > a:hover{
    color: goldenrod;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .sun{
        height: 100px;
        width: 100px;
        right: 25px;
        top: 75px;
    }  

    nav.navigation{
        display: flex;
        width: 100%;
        height: 10%;
        right: 0;
        background-color: rgba(252, 249, 227, 0.4);
        border-radius: 1%;
    }

    nav.navigation ul{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 10px;
            padding-left: 15px;
        }

    nav.navigation ul li{
            margin: 0 10px;
            font-size: 13px;
        }
    }

  