div.contact-form{
    width: 100%;
    margin: 120px auto;
    background-color: rgba(252, 249, 227, 0.8);
    padding: 20px 0;
    color: black;
    box-shadow: 10px 10px 60px 5px rgb(28, 87, 97);
}

div.contact-form h1{
    text-align: center;
    font-family: lato;
    font-size: 50px;
}

.text-primary{
    color: black;
}

div.contact-form form{
    width: 60%;
    margin: 0 auto;
    padding: 20px 0;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    div.contact-form h1{
        font-size: 40px;
    }

    div.contact-form form{
        width: 75%;
    }
  }

  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {

        div.contact-form h1{
            font-size: 60px;
        }

        div.contact-form form{
            width: 75%;
        }

    }