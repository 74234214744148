*{
    transition: all 1s;
}

h2{
    text-align: center;
}

div.project-map{
    display: flex;
    justify-content: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
}

div.project-card{
    background-color: rgba(0,0,0,0.5);
    border-radius: 20px;
    margin: 15px;
    text-align: center;
    padding: 5px;
    position: relative;
    font-family: dancing script;
    font-size: 20px;
    color: whitesmoke;
}

div.project-card:hover{
    transform: scale(1.01);
}

div.project-card button{
    opacity: 0;
    position: absolute;
    left: 35%;
    top: 0%;
    background-color: rgba(0,0,0,0.5);
    border: 2px solid white;
    color: whitesmoke;
    width: 120px;
    height: 50px;
    font-family: lato;
    font-size: 15px;
    transition: all 1s;
}

div.project-card:hover button{
   background: rgba(0,0,0,0.5);
   opacity: 1;
   top: 35%;
}

div.project-card img{
    border-radius: 10px;
    height: 200px;
    width: 400px;
}

div.project-detail{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50px 300px;
    grid-gap: 5px;
    background-color: rgba(0,0,0,0.5);
    padding: 20px 0;
}

div.project-detail img{
    border-radius: 20px;
    height: 300px;
    width: 550px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin: 30px 50px;
}

div.project-detail h2{
    text-align: center;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    color: whitesmoke;
    font-family: dancing script;
    font-size: 40px;
}

div.project-detail p{
    margin: 60px 10px;
    padding: 20px;
    color: whitesmoke;
    font-family: lato;
}

div.project-detail a{
    text-decoration: none;
    color: whitesmoke;
    margin-top: 40px;
    padding-left: 40%;
    font-family: lato;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    div.project-card{
        height: 100px;
        width: 200px;
    }

    div.project-card img{
        display: none;
    }

    div.project-card button{
        opacity: 1;
        left: 25%;
        top: 50%;
        width: 100px;
        height: 30px;
        font-size: 15px;
    }

    div.project-detail{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    div.project-detail h2{
        font-size: 30px;
    }

    div.project-detail img{
        height: 150px;
        width: 300px;
        margin: 10px 50px;
    }

    div.project-detail p{
        margin: 0 10px;
        font-size: 15px;
        padding: 0 20px;
    }

    div.project-detail a{
        display: inline-block;
        margin: 10px auto;
        padding: 0;
    }

    div.project-detail a > h3{
        font-size: 20px;
    }
}

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {

        div.project-card{
            height: 150px;
        }

        div.project-card img{
            height: 75px;
            width: 150px;
        }

        div.project-card button{
            opacity: 1;
            left: 20%;
            top: 75%;
            height: 30px;
            width: 90px;
        }

        div.project-detail{
            width: 750px;
            border-radius: 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        div.project-detail img{
            height: 200px;
            width: 400px;
            margin: 20px auto;
        }

        div.project-detail p{
            font-size: 15px;
            margin: 30px 10px;
        }

        div.project-detail a{
            display: inline;
            padding: 0;
            margin: 10px auto;
        }

        div.project-detail a > h3{
            display: inline;
        }

    }

  