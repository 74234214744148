div.about{
    background-color: rgba(252, 249, 227, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid lightslategray;
    margin: 0 0 50px 0;
    padding-bottom: 25px;
    box-shadow: 10px 10px 60px 5px rgb(28, 87, 97);
}

div.about > div.words {
    width: 100%;
}

div.about div.words h2{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-family: dancing script;
    font-size: 40px;
}

div.about div.words h3{
    font-size: 20px;
    padding: 0 100px;
    margin: 20px auto;
    font-family: lato;
    text-align: center;
}

a > img.resume{
    height: 300px;
    margin: 10px 30px;
}

a > p{
    text-align: center;
    font-family: lato;
    font-size: 20px;
}

a:hover{
    transform: scale(1.02);
}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    div.about div.words h2{
        font-size: 20px;
    }

    div.about div.words h3{
        font-size: 15px;
        padding: 0 50px;
    }

    a{
        display: flex;
        justify-content: center;
    }

    a > img.resume{
        height: 200px;
        margin: 10px 30px;
    }

    a > p{
        text-align: center;
        font-family: lato;
        font-size: 20px;
        margin-top: 40px;
    }
  }

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {

    div.about div.words h2{
        font-size: 25px;
    }

    div.about div.words h3{
        font-size: 15px;

    }


    a{
        display: flex;
        justify-content: center;
    }

    a > img.resume{
        height: 250px;
    }

    a > p {
        margin: 10%;
    }

    }

  