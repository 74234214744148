div.intro{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    border-bottom: 2px solid lightslategray;
}

div.intro img {
    height: 300px;
    border-radius: 100%;
    margin: 5%;
}

div.intro img:hover{
    transform: scale(1.01);
}

div.title{
    text-align: center;
    margin: 50px;
}

div.title h1{
    font-family: dancing script;
    font-size: 60px;
    margin-top: 60px;
}

div.title h2{
    font-family: lato;
    font-size: 35px;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    div.intro{
        flex-direction: column;
        align-items: center;
        padding: 60px;
    }

    div.intro img {
        height: 150px;
        width: 150px;
    }

    div.title{
        margin: 0 auto;
    }

    div.title h1{
        font-family: dancing script;
        font-size: 30px;
        margin: 0 auto;
    }
    
    div.title h2{
        font-family: lato;
        font-size: 15px;
    }
  }

  @media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

    div.intro img {
        height: 200px;
        width: 200px;
        margin-top: 50px;
    }

    div.title{
        margin: 100px auto;
    }

    div.title h1{
        font-family: dancing script;
        font-size: 35px;
        margin: 0 auto;
    }
    
    div.title h2{
        font-family: lato;
        font-size: 20px;
    }


}