div.footer{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

div.footer nav{
    width: 100%;
}

div.footer nav ul{
    width: 100%;
    display: flex;
    justify-content: center;
}

div.footer nav ul li{
    list-style-type: none;
    display: inline-block;
    margin-top: 20px;
    padding: 25px 25px;
}

div.footer nav ul li:hover{
    transform: scale(1.05);
}

div.footer img:first-of-type{
    height: 60px;
}

div.footer img:nth-of-type(2){
    height: 75px;
    display: inline-block;
}